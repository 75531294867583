import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Imageview from "../components/Imageview";
import playimg from "../assets/playimg.png";
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import img5 from "../assets/img5.webp";
import img6 from "../assets/img6.svg";
import img7 from "../assets/img7.svg";
import { HOMESCREEN } from "../constant";
import "../style.css";
import { Link } from "react-router-dom";
import VideoPlayer from "../components/VideoPlayer";
import { useNavigate } from "react-router-dom";
const Home: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const handleButton = () => {
    navigate("/bookanappointment")
  }
  return (
    <>
      <Grid
        container
        justifyContent="center"
        style={{
          marginTop: "-70px",
          marginBottom: "-60px",
          alignItems: "center",
        }}
      >
        <Imageview />
        <Grid item xs={12} md={10} lg={10} style={{ alignItems: "center" }}>
          <Card
            sx={{
              width: "90%",
              height: "auto",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: isSmallScreen ? "-40px" : "-70px",
              marginBottom: isSmallScreen ? "0" : "-10px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mb: isSmallScreen ? 0 : 3,
                mt: isSmallScreen ? "10px" : "35px",
                fontSize: isSmallScreen ? "4vw" : "34px",
                fontWeight: 700,
              }}
            >
              {HOMESCREEN.specialized}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ mt: isSmallScreen ? "20px" : "10px", marginBottom: "40px" }}
            >
              {[image1, image2, image3].map((item, index) => (
                <Grid item xs={12} key={index}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                      padding: 0,
                      marginBottom: "20px",
                    }}
                  >
                    <div>
                      <img
                        src={item}
                        alt={`Item ${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          opacity: 0.5,
                          borderTop: "1px solid rgba(0, 0, 0, 0.15)",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: isSmallScreen
                            ? "25%"
                            : isMediumScreen
                              ? "15%"
                              : "48%",
                          right: isSmallScreen ? "5%" : "20px",
                          transform: "translateY(-50%)",
                          textAlign: "center",
                          width: isSmallScreen
                            ? "50%"
                            : isMediumScreen
                              ? "50%"
                              : "40%",
                          height: isSmallScreen
                            ? "50%"
                            : isMediumScreen
                              ? "50%"
                              : "100%",
                          color: "black",
                          padding: isSmallScreen
                            ? "10px"
                            : isMediumScreen
                              ? "0px"
                              : "20px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                          zIndex: 2,
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            mt: isSmallScreen ? "0" : "2.5vw",
                            fontWeight: 500,
                            fontSize: isSmallScreen ? "2vw" : "20px",
                          }}
                        >
                          {HOMESCREEN.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{
                            mt: "2vw",
                            fontSize: isSmallScreen ? "2vw" : "18px",
                            fontWeight: 400,
                            textAlign: "left",
                            ml: isSmallScreen ? "2vw" : "20px",
                          }}
                        >
                          {HOMESCREEN.content}
                        </Typography>
                        <Button onClick={handleButton}
                          variant="outlined"
                          sx={{
                            color: "rgba(33, 33, 33, 1)", border: "1px solid rgba(120, 174, 55, 0.5)", backgroundColor: "rgba(233, 250, 210, 1)",
                            mt: isSmallScreen ? 0 : isMediumScreen ? 0 : "4vw",
                            fontSize: isSmallScreen ? "2vw" : "16px", fontWeight: 500,fontFamily:"Roboto",
                            '&:focus': {
                              border: " 1px solid rgba(120, 174, 55, 0.5)", backgroundColor: "rgba(233, 250, 210, 1)",
                            },
                            '&:hover': {
                              border: " 1px solid rgba(120, 174, 55, 0.5)", backgroundColor: "rgba(233, 250, 210, 1)",borderColor: "#78AE37",
                            }
                          }}
                        >
                          {HOMESCREEN.buttonlabel}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <div
        style={{
          margin: "0 auto",
          width: "100%",
          height: "auto",
          backgroundColor: "#E6F9CD",
          padding: "40px",
          zIndex: -2,
        }}
      >
        <VideoPlayer
          videoId="aGkp1CVzG1Y"
          width="560"
          height="345"
        />
      </div>
      <Grid container style={{ justifyContent: "center", padding: "40px 0px" }}>
        <Grid item style={{ position: "relative" }}>
          <img
            src={img5}
            alt="Image 2"
            style={{
              objectFit: "cover",
              marginTop: "25px",

              marginRight: isSmallScreen ? "0px" : "30px",
              width: "304px",
              height: "440px",

              borderRadius: "10px",
            }}
          />
          <h3
            style={{
              position: "absolute",
              top: "83%",
              left: "48%",
              transform: "translate(-50%, -50%)",
              zIndex: "1",
              textAlign: "center",
              cursor: "pointer",
              color: "#000000",
              margin: "0",
              width: "218.9px", fontFamily: "Nunito Sans",
            }} className="footer-link">
             {HOMESCREEN.MedicalOzoneTherapy}
          </h3>
        </Grid>

        <Grid item style={{ position: "relative" }}>
          <img
            src={img6}
            alt="Image 2"
            style={{
              objectFit: "cover",
              marginTop: "30px",
              width: "304px",
              marginRight: isSmallScreen ? "0px" : "30px",
              height: "429px",
              borderRadius: "10px",

            }}
          />
          <h3
            style={{
              position: "absolute",
              top: "83%",
              left: "48%",
              transform: "translate(-50%, -50%)",
              zIndex: "1",
              textAlign: "center",
              cursor: "pointer",
              color: "#000000",
              margin: "0",
              width: "218.9px", fontFamily: "Nunito Sans",
            }} className="footer-link"  >
           
            {HOMESCREEN.ChelationTherapy}
          </h3>
        </Grid>
        <Grid item style={{ position: "relative" }}>
          <img
            src={img7}
            alt="Image 2"
            style={{
              objectFit: "cover",

              marginTop: "20px",
              width: "314px",
              marginRight: isSmallScreen ? "0px" : "30px",
              height: "449px",

              borderRadius: "10px",
            }}
          />
          <h3
            style={{
              position: "absolute",
              top: "83%",
              left: "48%",
              transform: "translate(-50%, -50%)",
              zIndex: "1",
              textAlign: "center",
              cursor: "pointer",
              color: "#000000",
              margin: "0",
              width: "218.9px", fontFamily: "Nunito Sans",
            }} className="footer-link"

          >
            {HOMESCREEN.ivtext}
          </h3>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;