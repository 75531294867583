const NavBarData = [
    {
      id: 1,
      text: "Therapies",
      link: "/therapies",
      subMenus: [
        { text: "Medical Ozone", link: "/medicalozone" },
        { text: "Intravenous Anti oxidants", link: "/intravenous" },
        { text: "IV Nutrients", link: "/iv" },
        { text: "Detoxification", link: "/detox" },
        { text: "PainManagement", link: "/pain" },
        { text: "EECP", link: "/eecp" },
      ],
      nestedMenus: {
        Detoxification: [
          { text: "FIR", link: "/fir" },
          { text: "NIR", link: "/nir" },
          { text: "Red light therapy", link: "/redtherapy" },
          { text: "Korean cupping", link: "/korean" },
        ],
        PainManagement: [
          { text: "PEMF", link: "/pemf" },
          { text: "Hydrosun", link: "/hydrosun" },
          { text: "Prozone", link: "/prozone" },
          { text: "PRP", link: "/prp" },
          { text: "IMRS", link: "/imrs" },
          { text: "Acupuncture", link: "/acupuncture" },
          { text: "MarmaPoints", link: "/marma" },
          { text: "Pain portal blocks", link: "/painportal" },
          { text: "Laser therapy", link: "/laser" },
        ],
      },
    },
    {
      id: 2,
      text: "Diagnostics",
      link: "/diagnostics",
      subMenus: [
        { text: "Live Blood Analysis", link: "/liveblood" },
        { text: "Complete metabolic profile", link: "/metabolicprofle" },
        { text: "Hair Mineral analysis", link: "/mineralanalysis" },
        { text: "Gene profiling", link: "/gene" },
        { text: "Autonomic nervous system analysis", link: "/nervoussystem" },
        { text: "Alfasight thermogram", link: "/alfasight" },
        { text: "Mitochondrial function assessment", link: "/mitochondrial" },
      ],
    },
    {
      id: 3,
      text: "Integrative Cancer Health",
      link: "/cancer",
      subMenus: [
        { text: "Immunotherapy", link: "/immunotherapy" },
        { text: "SOT", link: "/sot" },
        { text: "IPT", link: "/ipt" },
        { text: "IPCT", link: "/ipct" },
        { text: "Detoxification", link: "/detoxification" },
        { text: "EFT & Music therapy", link: "/eft" },
        { text: "Rigvir", link: "/rigvir" },
        { text: "Oncotherm -soon", link: "/oncotherm" },
        { text: "Sonodynamic therapy -soon", link: "/sonodyamic" },
      ],
      nestedMenus: {
        SOT: [
       { text: "High Dose Vit C Therapy", link: "/vitc" },
       { text: "Medical Ozone", link: "/mozone" },
       { text: "GCMAF", link: "/gcmaf" },
       { text: "Anthroposophic Medicine", link: "/anthrosophic" },
       { text: "Gerson Therapy", link: "/gerson" },
     ],
  },
    },
    {
      id: 4,
      text: "Integrative Mental Health",
      link: "/mentalhealth",
      subMenus: [
        { text: "Colour therapy", link: "/colourtherapy" },
        { text: "Art therapy", link: "/arttherapy" },
        { text: "Movement Therapy", link: "/movement" },
        { text: "Sound therapy", link: "/sound" },
      ],
    },
  ];
export default NavBarData;  