import React,{useState,useEffect} from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { TEXT_MESSAGES,contact_info } from "../constant";
import "../style.css";
import PheonixFooterProps from "../interfaces/PheonixFooterProps";
import instaimage from "../assets/instagram1.webp";
import fbimage from "../assets/fb.webp";
import "../style.css"
import insta from "../assets/instagarmm.webp";
import facebook from "../assets/facebook.webp";
import youtube from "../assets/youtube.webp";
import linkdin from "../assets/linkdin.webp";
import { Instagram } from "@mui/icons-material";

const Footer: React.FC<PheonixFooterProps> = ({ style }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{backgroundColor:"black"}}>
    <footer
      style={{
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "#282828",opacity:"85%",
        color: "#fff",
        fontFamily: "Roboto",
      }}
    >
    <Container sx={{ paddingTop: "50px", paddingBottom: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "350px",
              marginBottom: isSmallScreen ? "20px" : 0,
            }}
          >
            <Typography
              style={{
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "32px",
                marginBottom: "10px",
                marginTop: "15px",
              }}
            >
              {TEXT_MESSAGES.NEWLIFE}
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "32px",
              }}
            >
              {TEXT_MESSAGES.TEXT1}{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              marginBottom: 4,
              marginLeft: isSmallScreen ? 0 : { xs: 0, md: 4, sm: 2 },
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: 500,
                fontSize: "18px",
                color: "#FFFFFF",
                marginBottom: "10px",
                marginTop: "15px",
              }}
            >
              {TEXT_MESSAGES.QuickLinks}
            </Typography>

            <Link
              to="/testimonials"
              style={{ color: "inherit" }}
              onClick={scrollToTop}
              className="footer-link"
            >
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  marginBottom: "5px",
                }}
              >
                {TEXT_MESSAGES.Testimonals}
              </Typography>
            </Link>
            <Link
              to="/about-us"
              style={{ color: "inherit" }}
              onClick={scrollToTop}
              className="footer-link"
            >
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  marginBottom: "5px",
                }}
              >
                {TEXT_MESSAGES.aboutus}
              </Typography>
            </Link>
            <Link
              to="/contact-us"
              style={{ color: "inherit" }}
              onClick={scrollToTop}
              className="footer-link"
            >
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  marginBottom: "5px",
                }}
              >
                {TEXT_MESSAGES.contactus}
              </Typography>
            </Link>
            <Link
              to="/bookanappointment"
              style={{ color: "inherit" }}
              onClick={scrollToTop}
              className="footer-link"
            >
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  marginBottom: "5px",
                }}
              >
                {TEXT_MESSAGES.APPOINTMENT}
              </Typography>
            </Link>
          </Box>
          <Box sx={{ marginRight: 2, marginBottom: 4 }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: 500,
                fontSize: "18px",
                color: "#FFFFFF",
                marginBottom: "10px",
                marginTop: isSmallScreen ? 0 : "15px",
              }}
            >
              {TEXT_MESSAGES.contactus}
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "14px",
                fontFamily: "Roboto",
                marginBottom: "10px",
              }}
            >
              {TEXT_MESSAGES.call}{" "}
              <a
                href={TEXT_MESSAGES.phonelink}
                style={{
                  color: "#FFFFFF",
                  marginLeft: "5px",
                  marginTop: "10px",
                }}
                className="footer-link"
              >
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    marginBottom: "10px",
                  }}
                >
                  {TEXT_MESSAGES.phone}
                </Typography>
              </a>{" "}
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "14px",
                fontFamily: "Roboto",
                marginBottom: "10px",
              }}
            >
              {TEXT_MESSAGES.Email}{" "}
              <a
                href={TEXT_MESSAGES.maillink}
                style={{
                  color: "#FFFFFF",
                  marginLeft: "5px",
                  marginTop: "10px",
                }}
                className="footer-link"
              >
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                >
                  {TEXT_MESSAGES.mailid}
                </Typography>
              </a>{" "}
            </Typography>
          </Box>
          <Box sx={{ marginRight: 2, marginBottom: 4 }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: 500,
                fontSize: "18px",
                color: "#FFFFFF",
                marginBottom: "10px",
                marginTop: isSmallScreen ? 0 : "15px",
              }}
            >
              {contact_info.FOLLOW}
            </Typography>
            <Box id="follow" sx={{
          display: "flex", gap: 1,
          marginTop: "10px"
        }}>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <img
              src={facebook}
              alt="facebook"
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </a>
          <a
            href="https://in.linkedin.com/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <img
              src={linkdin}
              alt="Linkdin"
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </a>
          <a
            href="https://youtube.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <img
              src={youtube}
              alt="youtube"
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <img
              src={insta}
              alt="Instagram"
              style={{
                width: "24px",
                height: "24px",
               
              }}
            />
          </a>
   
        </Box>
          </Box>
        </Box>
  
      </Container>
    </footer>
          <div style={{ textAlign: 'center', fontFamily: 'Roboto',  color: " rgba(255, 255, 255, 1)", fontSize: '16px', fontWeight: 400, lineHeight:"25.56px",
          marginTop: "10px", position: "static", bottom: 0, left: 130, right: 0 }}>
          &copy;{currentYear}
          <span> {TEXT_MESSAGES.FOOTER_TXT}</span> <a href='https://www.nulifewellnesscentre.com/' target="_blank" rel="noopener noreferrer"
            style={{ color: "rgba(255, 255, 255, 1)", fontFamily: "Roboto", fontWeight: 400, textDecoration: "none", fontSize: '16px' }}>
              </a>
        </div>
        </div>
  );
};
export default Footer;